.label {
  display: none;
}

.marker:hover .label {
  display: block;
}

.map {
  overflow: hidden;
  cursor: grab;
  touch-action: none !important;
}

.map:active {
  cursor: grabbing;
}

.map-container {
  position: relative;
  touch-action: none !important;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  z-index: 1000;
}

.no-wheel {
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
